import classNames from "classnames";
import { clone, filter, isEmpty } from "lodash";
import Checkbox from "../common/Checkbox";
import { useTranslation } from "react-i18next";

const CheckboxList = (props) => {
  const { t } = useTranslation();
  const {
    id = "",
    label,
    options,
    value,
    onChange,
    className,
    required,
    disabled,
    subLabel,
    labelClassName,
  } = props;

  const onChangeValue = (newVal) => {
    let result = clone(value) || [];
    if (result.includes(newVal)) {
      result = result.filter((r) => r !== newVal);
    } else {
      result.push(newVal);
    }
    onChange(result);
  };

  return (
    <div className="flex flex-col w-full text-gray-900 dark:text-gray-100 text-left">
      <div
        className={classNames("flex text-base pb-4", {
          [labelClassName]: !!labelClassName,
          "opacity-50": disabled,
        })}
      >
        {label}
        {!!required && <span className="text-red-500 pl-2">*</span>}
      </div>
      {!!subLabel && <p className="text-left text-md mb-2">{subLabel}</p>}
      <div
        className={classNames("flex flex-col", {
          [className]: !!className,
        })}
      >
        {options.map((option) => {
          const { label, value: currentVal, child, disabled: isDisable } = option;
          const isChecked = value.includes(currentVal);
          return (
            <Checkbox
              key={id + currentVal}
              id={id + currentVal}
              checked={isChecked}
              label={t(label)}
              child={child}
              onChange={() => onChangeValue(currentVal)}
              className="py-2"
              disabled={disabled || isDisable}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CheckboxList;
